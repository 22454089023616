import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const PortfolioCard = ({ image, title, url, category }) => {
  return (
    <Link
      to={url}
      className="bg-white py-16 px-12 break-inside text-center overflow-hidden border-gray-200 border-2"
    >
      <h3 className="text-2xl font-bold mb-3 text-gray-800">{title}</h3>
      <p className="text-gray-500 mb-5">{category}</p>
      <GatsbyImage image={image} alt={title} />
    </Link>
  );
};

export default PortfolioCard;
