import React from "react";
import Layout from "../../components/layout/Layout";
import PortfolioCard from "../../components/ui/cards/PortfolioCard";
import { useStaticQuery, graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";

const PortfolioPage = () => {
  const query = useStaticQuery(graphql`
    query {
      portfolio: allFile(
        sort: { fields: [base] }
        filter: { relativeDirectory: { eq: "portfolio" } }
      ) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(width: 600)
            }
          }
        }
      }
    }
  `);

  const searchPortfolioImage = (images, fileName) =>
    images.find((image) => image.node.name === fileName);

  const allProjects = [
    {
      title: "eubezpieczeniaonline.pl",
      description:
        "Strona internetowa dla przedstawiciela ubezpieczeń z pełną integracją z kontem na Facebooku, wspierająca działania marketingowe w mediach społecznościowych.",
      image: searchPortfolioImage(
        query.portfolio.edges,
        "eubezpieczeniaonline"
      ),
      url: "/portfolio/eubezpieczeniaonline",
      category: "www",
    },
    {
      title: "nieladakawa.pl",
      image: searchPortfolioImage(query.portfolio.edges, "nieladakawa"),
      url: "/portfolio/nieladakawa",
      category: "www",
    },
    {
      title: "wdresie.pl",
      image: searchPortfolioImage(query.portfolio.edges, "wdresie"),
      url: "/portfolio/wdresie",
      category: "e-commerce",
    },
    {
      title: "seepoint.pl",
      image: searchPortfolioImage(query.portfolio.edges, "seepoint"),
      url: "/portfolio/seepoint",
      category: "www",
    },
    {
      title: "perfumcity.pl",
      image: searchPortfolioImage(query.portfolio.edges, "perfumcity"),
      url: "/portfolio/perfumcity",
      category: "e-commerce",
    },
  ];

  return (
    <Layout pageTitle="Portfolio">
      <section className="container mx-auto px-5">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {allProjects.map(
            ({ image, title, description, url, category }, index) => (
              <PortfolioCard
                key={index}
                image={getImage(image.node)}
                title={title}
                description={description}
                url={url}
                category={category}
              />
            )
          )}
        </div>
      </section>
    </Layout>
  );
};

export default PortfolioPage;
